import { render, staticRenderFns } from "./screened.vue?vue&type=template&id=66cfb600&scoped=true"
import script from "./screened.vue?vue&type=script&lang=js"
export * from "./screened.vue?vue&type=script&lang=js"
import style0 from "./screened.vue?vue&type=style&index=0&id=66cfb600&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66cfb600",
  null
  
)

export default component.exports